import React from "react";
import Layout from "../layouts/en";
import MapWithAnOverlayView from "../components/LocationMap.js";
import { Container, Row, Col } from "reactstrap";
import SEO from "../components/seo.js";

const features = [
  { number: "12 km", title: "to center of Košíce" },
  { number: "18 km", title: "to center of Prešov" },
  { number: "98 km", title: "to borders of Poland" },
  { number: "97 km", title: "to borders of Ukraine" },
  { number: "38 km", title: "to borders of Hungary" },
  { number: "21 km", title: "to Košice airport" }
];

const SecondPage = props => (
  <Layout location={props.location}>
    <div>
      <MapWithAnOverlayView
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBOh1wAZLx_f2riCJadaLxEpjUqkGX_Gy8&v=3.exp&libraries=geometry,drawing,places"
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `600px` }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />
      <Container fluid className="locations">
      <Container fluid>
         <Row className="">
          <Col md={10} className="features container-basic pt-5 pb-5 f-table-cell">
            <Row>
              {features.map((feature, index) => 
              <Col key={index} className="text-center" xl={4} lg={6} sm={12}>
                <div className="feature-component">
                  <div className="feature-number">
                    <p dangerouslySetInnerHTML={{ __html: feature.number }}></p>
                  </div>
                  <div className="feature-title">
                    <h5 dangerouslySetInnerHTML={{ __html: feature.title }}></h5>
                  </div>
                </div>
              </Col>)}
            </Row>
          </Col>
        </Row>
      </Container>
      <Container fluid className="container-places locations pb-0">
          <Row>
            <h2>Accessibility </h2>
            <p>
            The Park has excellent accessibility because it is located on D1 motorway exit 
            Nová Polhora between Košice and Prešov. These cities are easily accessible by 
            car but also by public transport. It takes only 30 minutes by car from the 
            Košice Airport.
            </p>
          </Row>
        </Container>
      </Container>
      <Container fluid className="locations">
        <Container fluid className="container-places locations pt-0">
          <Row>
            <Col md={6} className="col-utilization">
              <div className="descrtipton-content container-basic div-height justify">
                <h2>Description</h2>
                <p>
                The Nová Polhora Logistics and Industrial Park comprises individual 
                sectors from S2 to S7. The Park lies on the main development axis 
                Košice-Prešov. The main entrance to the Logistics and Industrial 
                park is from D1 motorway by feeder Nová Polhora, which is connected 
                to the road 3325.
                </p>
                <h2>Modern building complexes</h2>
                <p>
                Buildings in the park are suitable for many activities for example 
                logistics, retailers, wholesale trade, e-commerce or light 
                manufacturing. Buildings dispose also with modern office units.


                </p>
              </div>
            </Col>
            <Col md={6} className="col-utilization">
              <div className="container-basic div-height blue flex">
                <div className="list-places">
                  <ul>
                    <li> Possible cross-docking</li>
                    <li>24-hour security, fenced area</li>
                    <li>Flexible lease terms</li>
                    <li>Flexible office layouts</li>
                    <li>Development opportunity</li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
    <SEO title="Lokalita" />
  </Layout>
);

export default SecondPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
