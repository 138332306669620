import React from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  OverlayView,
  Marker,
  Polyline,
  InfoWindow
} from "react-google-maps";
import { compose, withStateHandlers } from "recompose";
import point from '../static/images/red-marker.svg';
import nic from '../static/images/nic.svg';
import pointNpi from '../static/images/pointer-logo.svg';
import "./OverlayMap.css";
import mapStyle from "./mapStyle.js"

const { MarkerWithLabel } = require("react-google-maps/lib/components/addons/MarkerWithLabel");

const options = {
  strokeOpacity: 0,
  icons: [{
    icon: {
      path: 'M 0,-1 0,1',
      strokeOpacity: 1,
      strokeColor: '#47a64a',

      scale: 2
    },
    offset: '0',
    repeat: '10px',
    fill: "#989898"
  }],

};



const defaultOptions = {
    styles: mapStyle,
    zoomControl: false,
    mapTypeControl: false,
    scaleControl: true,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: true
  

}

const MapWithAnOverlayView = compose(
  withStateHandlers(() => ({
    ukraine: false,
    poland: false,
    czechia: false,
    hungary: false,
    germany: false,
    austria: false,
  }), {
    germanyOpen: ({ germany }) => () => ({
      germany: !germany,
    }),
    ukraineOpen: ({ ukraine }) => () => ({
      ukraine: !ukraine,
    }),
    polandOpen: ({ poland }) => () => ({
      poland: !poland,
    }),
    czechiaOpen: ({ czechia }) => () => ({
      czechia: !czechia,
    }),
    hungaryOpen: ({ hungary }) => () => ({
      hungary: !hungary,
    }),
    austriaOpen: ({ austria }) => () => ({
      austria: !austria,
    })
  }),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap defaultZoom={6} defaultCenter={{ lat: 50.22254, lng: 21.308025 }} defaultOptions={defaultOptions}>
     <MarkerWithLabel
      position={{ lat: 50.4447478, lng: 30.262947, }}
      labelAnchor={{ lat: 50.4447478, lng: 28.305947, }}
      labelStyle={{fontSize: "20px"}}
      icon={{
        url: nic,
      }}
    >
      <div>Kiev</div>
    
    </MarkerWithLabel>
     <Marker
      position={{ lat: 50.4447478, lng: 30.515947, }}
      onClick={props.ukraineOpen}
      icon={{
        url: point,
      }}
    >
     {props.ukraine && <InfoWindow onCloseClick={props.ukraineOpen}>
        <p>97 km to border with Ukraine
        <br/>
        310 km to Lviv
        <br/>
        862km to Kiev
        </p>
      </InfoWindow>}
     </Marker> 
     <MarkerWithLabel
      position={{ lat: 52.222855, lng: 20.4011119 }}
      labelAnchor={{ lat: 52.222855, lng: 20.7311119 }}
      labelStyle={{fontSize: "20px"}}
      icon={{
        url: nic,
      }}
    >
      <div>Varšava</div>
    
    </MarkerWithLabel>
     <Marker
      position={{ lat: 52.222855, lng: 21.0311119 }}
      onClick={props.polandOpen}
      icon={{
        url: point,
      }}
    >
       {props.poland && <InfoWindow onCloseClick={props.polandOpen}>
       <p>98 km to border with Poland
        <br/>
        475 km to Warsaw
        </p>
      </InfoWindow>}
     </Marker> 
     <MarkerWithLabel
      position={{ lat: 52.5065133, lng: 12.904555 }}
      labelAnchor={{ lat: 52.5065133, lng: 13.374555 }}
      labelStyle={{fontSize: "20px"}}
      icon={{
        url: nic,
      }}
    >
      <div>Berlin</div>
    
    </MarkerWithLabel>
     <Marker
      position={{ lat: 52.5065133, lng: 13.374555 }}
      onClick={props.germanyOpen}
      icon={{
        url: point,
      }}
    >
       {props.germany && <InfoWindow onCloseClick={props.germanyOpen}>
       <p>700 km to  Germany borders
        <br/>
        870 km to Berlin
        </p>
      </InfoWindow>}
     </Marker> 
     <MarkerWithLabel
      position={{ lat: 50.123413, lng: 14.006031 }}
      labelAnchor={{ lat: 50.073413, lng: 14.436031 }}
      labelStyle={{fontSize: "20px"}}
      icon={{
        url: nic,
      }}
    >
      <div>Praha</div>
    
    </MarkerWithLabel>
     <Marker
      position={{ lat: 50.073413, lng: 14.436031 }}
      onClick={props.czechiaOpen}
      icon={{
        url: point,
      }}
    >
       {props.czechia && <InfoWindow onCloseClick={props.czechiaOpen}>
       <p>315 km to border with Czech republic
        <br/>
        650 km to Prague
        </p>
      </InfoWindow>}
     </Marker>
     <MarkerWithLabel
      position={{ lat: 48.2005998, lng: 15.7499771 }}
      labelAnchor={{ lat: 48.2005998, lng: 16.3699771 }}
      labelStyle={{fontSize: "20px"}}
      icon={{
        url: nic,
      }}
    >
      <div>Viedeň</div>
    
    </MarkerWithLabel> 
     <Marker
      position={{ lat: 48.2005998, lng: 16.3699771 }}
      onClick={props.austriaOpen}
      icon={{
        url: point,
      }}
    >
       {props.austria && <InfoWindow onCloseClick={props.austriaOpen}>
       <p> 430 km to border with Austria
       <br/>
         500 km to Vienna
        </p>
      </InfoWindow>}
     </Marker> 
     <MarkerWithLabel
      position={{ lat: 47.4911281, lng: 18.1502201 }}
      labelAnchor={{ lat: 47.4911281, lng: 19.0502201 }}
      labelStyle={{fontSize: "20px"}}
      icon={{
        url: nic,
      }}
    >
      <div>Budapešť</div>
    
    </MarkerWithLabel>
     <Marker
      position={{ lat: 47.4911281, lng: 19.0502201 }}
      onClick={props.hungaryOpen}
      icon={{
        url: point,
      }}
    >
       {props.hungary && <InfoWindow onCloseClick={props.hungaryOpen}>
       <p>38 km to border with Hungary
        <br/>
         275 km to Budapest
        </p>
      </InfoWindow>}
     </Marker> 
    <Marker
      position={{ lat: 48.8290715, lng: 21.312374 }}
      icon={{
        url: pointNpi,
      }}
    />
    <Polyline
      path={[
        { lat: 48.2005998, lng: 16.3699771},
        {lat: 48.8290736, lng: 21.312375}
      ]}
      options={options}
    ></Polyline>
     <Polyline
      path={[
        {lat: 47.4911281, lng: 19.0502201},
        {lat: 48.8290736, lng: 21.312375}
      ]}
      options={options}
    ></Polyline>
     <Polyline
      path={[
        {lat: 50.073413, lng: 14.436031},
        {lat: 48.8290736, lng: 21.312375}
      ]}
      options={options}
    ></Polyline>
     <Polyline
      path={[
        {lat: 52.5065133, lng: 13.374555},
        {lat: 48.8290736, lng: 21.312375}
      ]}
      options={options}
    ></Polyline>
     <Polyline
      path={[
        {lat: 52.222855, lng: 21.0311119},
        {lat: 48.8290736, lng: 21.312375}
      ]}
      options={options}
    ></Polyline>
     <Polyline
      path={[
        {lat: 50.4447478, lng: 30.515947},
        {lat: 48.8290736, lng: 21.312375}
      ]}
      options={options}
    ></Polyline>
    <OverlayView
      // position={{ lat: 48.834540, lng: 21.308025 }}
      bounds={{
        ne: { lat: 66.822779, lng: 21.308725 },
        sw: { lat: 48.834711, lng: 21.322485 }
      }}
      /*
       * 1. Specify the pane the OverlayView will be rendered to. For
       *    mouse interactivity, use `OverlayView.OVERLAY_MOUSE_TARGET`.
       *    Defaults to `OverlayView.OVERLAY_LAYER`.
       */
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      /*
       * 2. Tweak the OverlayView's pixel position. In this case, we're
       *    centering the content.
       */
      // getPixelPositionOffset={getPixelPositionOffset}
      /*
       * 3. Create OverlayView content using standard React components.
       */
    >
      <div>
       
      </div>
    </OverlayView>
  </GoogleMap>
));

export default MapWithAnOverlayView;
